@import 'theme/mixins';

.card {
  margin: 0;
  overflow: visible;
  width: 100%;
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-start: 0px;
  --padding-end: 20px;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  border-bottom: 1px solid var(--ion-border-color);

  ion-row {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

.avatar {
  @include cardAvatar(40px);
  margin-inline-end: 8px;

  .title {
    color: var(--ion-text-color-reversed);
  }

  .alertIcon {
    color: var(--ion-color-warning);
  }
}

.avatarDiv {
  display: flex;
  align-items: center;
}

.readSpacing {
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.readDot {
  @extend .readSpacing;
  background-color: var(--mipro-color-unread);
}

.readCol {
  min-width: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 0px;
}

.hideReadCol {
  display: none;
}

.container {
  width: 100%;
}

.wrapper {
  flex: 1;

  .title {
    margin-bottom: 2px;
  }
  ion-row {
    margin-bottom: -1px;
  }

  ion-col {
    --ion-grid-column-padding: 0;
  }

  .text {
    font-size: 14px;
    margin-inline-end: 8px;
  }

  .badgeRow {
    display: flex;
    align-items: center;
  }
  .descriptions {
    display: flex;
    gap: 16px;
  }

  .badge {
    padding-inline-start: 0;
    background: transparent;
    .linePrefixText {
      display: inline-block;
      font-size: 12px;
      line-height: 17px;
      &.commentText {
        font-weight: normal;
        color: var(--ion-text-color-light);
      }
      &.priority {
        color: var(--mipro-color-light-red);
      }
      &.comment {
        color: var(--mipro-color-dark-text);
      }
    }
    .prefixIcon {
      font-size: 14px;
      width: 12px;
      height: 14px;
      &.comment {
        color: var(--mipro-color-dark-text);
      }
      &.priority {
        color: var(--mipro-color-light-red);
      }
    }
  }

  .description {
    display: block;
    line-height: 14px;
  }

  .pick12Wrapper {
    float: left;
    height: 14px;
  }

  .aging {
    // DOC: min width needed to avoid last year dates breaking lines
    min-width: 65px;
    text-align: right;
    margin-inline-start: auto;
  }

  .snooze {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    color: var(--ion-color-warning);
  }
}

.contentRow {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 100%;
    margin-bottom: 3px;
  }
}

.statusIcon {
  &.statusPrimary {
    color: var(--color-followUp-active);
    font-size: 14px;
  }
}

.pick12Icon {
  width: 13px;
  height: 16px;
  margin-right: 2px;
  position: relative;
  top: -2px;
}

.deleteSlider {
  background: var(--ion-color-secondary);
  ion-button{
    --ion-text-color: var(--ion-color-secondary-contrast);
  }

  &.disabled {
    background: var(--mipro-color-dark-gray);
  }
}

.readSlider {
  background: var(--mipro-color-primary-tint);
  width: 85px;
  ion-button{
    --ion-text-color: var(--ion-color-secondary-contrast);
  }
  span{
  white-space: pre-line!important;
  width: 45px;
  }
  &.disabled {
    background: var(--mipro-color-dark-gray);
  }
}

.corpIcon {
  position: relative;
  top: -3px;
}
.corpIconWrapper {
  float: left;
  height: 14px;
}

.cancelBadge {
  background: var(--mipro-color-dark-gray);
}

.unLinkSlider {
  background: var(--mipro-color-light-blue);
  ion-button {
    --ion-text-color: var(--ion-color-light);
  }
  span {
    font-size: 14px;
    font-weight: 500;
  }
  svg {
    width: 14px;
    height: 12px;
  }
}
