.innerButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: pre-wrap;
}

.itemText {
  line-height: 17px;
  text-transform: uppercase;
  --text-default-color: var(--ion-text-color-reversed);
  width: 100%;
  text-align: center;
  margin-top: 12px;
}

.reportIcon {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  width: 38.25px;
  height: 33.37px;
}

.sales {
  background: #11ba5e;
}

.webPerformance {
  background: #d1cfd7;

  .itemText {
    --text-default-color: var(--mipro-color-dark-text);
  }
}

.pick12Detail {
  background: #f51900;
}

.pick12Leaderboard {
  background: #bf1300;
}

.costSavings {
  background: #efb519;
}

.salesPlays {
  background: #15b7ae;
}

.opsReports {
  background: #273654;
}

.webActivity {
  background: #0265d2;
}

.endOfMonth {
  background: #347ae2;
}

.unbilled {
  background: #ff7a00;
}

.proTips {
  background: #f51900;

  img {
    filter: brightness(10) grayscale(1);
  }
}

.openQuotes {
  background: #95ee24;

  .itemText {
    --text-default-color: var(--mipro-color-dark-text);
  }
}
