.emptyResult {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  filter: grayscale(1);
}

.filter {
  margin-bottom: 16px;

  .filterButton {
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    height: 30px;

    &::part(native) {
      --padding-top: 0px;
    }

    &.hasFilters {
      padding: 0px 10px;
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      min-width: 81px;
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 14px;
    }

    span {
      font-weight: 600 !important;
    }
  }
}

.listHeader {
  --min-height: unset;
  --background: var(--mipro-color-white);
  text-transform: uppercase;
  --padding-top: 16px;
  --padding-bottom: 16px;
}
