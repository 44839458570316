@import 'theme/mixins';

.wrapper {
  border: 1px solid var(--mipro-color-dark);
  border-radius: 6px;
  flex: 1 1;
  min-width: 1px;
  font-size: 14px;
  overflow-y: scroll;

  & :global(.ql-editor) {
    outline: none;
  }

  & :global(.ql-blank)::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    pointer-events: none;
    position: absolute;
  }

  & > * {
    margin-block: 2px;
    font-size: 14px;
    font-weight: 400;
    white-space: break-spaces;
    line-height: normal;
  }

  & :global(.mention[data-id]) {
    color: var(--mipro-color-dark-blue);
    text-transform: capitalize;
  }

  & :global(.mention span[contenteditable].active) {
    background-color: #e2e8f0;
  }

  &.readOnly {
    border: none;

    .input {
      margin: 0px;
      max-height: unset;
    }
  }

  &.disabled {
    background: var(--mipro-color-disabled);
    border-color: var(--mipro-color-disabled-shade);
  }

  &:focus-within {
    border-color: var(--mipro-color-primary);
    box-shadow: 0 0 9px 0 rgba(12, 108, 212, 0.302);
  }

  .input {
    margin: 14px;
    max-height: 200px;
  }
}

.qlMentionListContainer {
  width: calc(100vw - 32px) !important;
  left: 0 !important;
  margin: 0 16px;
  padding: 0;
  overflow-y: scroll;
  background: var(--mipro-color-white);
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  border: solid 1px #e2e8f0;
  max-height: 340px;
}

.qlMentionList {
  margin: 0px;
  padding: 0px;
  list-style: none;

  .tagEmployee {
    background-color: var(--mipro-color-white);
    border-bottom: 1px solid #e2e8f0;
    margin-inline: 16px;
    padding: 16px;

    color: var(--mipro-color-dark-text);
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-weight: 400;

    &.emptyTagEmployee {
      height: auto;
    }
  }

  .firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nameRow {
      display: flex;
      align-items: center;
    }

    span {
      color: var(--mipro-color-dark-text);
      font-family: var(--ion-font-family-alt);
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
    }

    .name {
      @include textEllipsis(1);
    }

    .status {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      margin-inline-end: 8px;

      &.away {
        background: var(--mipro-color-light-yellow);
      }

      &.active {
        background: var(--mipro-color-online-green);
      }

      &.inactive {
        background: var(--mipro-color-dark-gray);
      }
    }

    .lastLogin {
      color: var(--ion-text-color-light);
      font-size: 12px;
    }
  }

  .secondRow {
    display: flex;
    align-items: center;
    padding-inline-start: 20px;
    text-transform: uppercase;

    .title {
      @include textEllipsis(1);
    }

    .separator {
      display: inline-block;
      padding: 0 4px;
    }
  }

  .emptyFirstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nameRow {
      display: flex;
      align-items: center;
    }

    span {
      color: var(--mipro-color-dark-text);
      font-family: var(--ion-font-family);
      font-size: 15px;
      font-weight: bold;
      line-height: 1.33;
    }
  }
  .emptySecondRow {
    display: flex;
    align-items: center;
    padding-block-start: 4px;

    span {
      font-family: var(--ion-font-family);
      font-size: 14px;
      line-height: 1.43;
      color: var(--mipro-color-dark-gray);
    }

    .separator {
      display: inline-block;
      padding: 0 4px;
    }
  }
}
