.cardWrapper {
  padding-block: 16px;
  background: var(--ion-color-primary-contrast);
}

.linkProTipBtn {
  width: 100%;
  --ion-color-primary: var(--mipro-color-light-blue);
}

.proTipCard {
  background: var(--ion-background-color);
  padding-block: 16px;
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 16px;
  }

  .proTipHeaderActions {
    display: flex;
  }

  .actionCard {
    border-bottom: none;
    ion-row {
      padding-inline-start: 11px;
    }
  }

  .proTipMsg {
    padding-inline: 16px;
    margin-block-end: 0;
    ion-col {
      padding: 24px 20px;
    }
    span:first-child {
      color: var(--mipro-color-dark-gray);
      font-weight: normal;
      margin-bottom: 16px;
    }
  }
}


.noMargin {
  margin-top: 0px;
}
