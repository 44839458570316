%input-style {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;
}

.modal {
  --padding-top: 0;
  --padding-bottom: 0;
}

.modalHeader {
  margin-bottom: 16px;
}

.checkbox {
  margin: 8px 8px 8px 0;
}

.buttonWrapper {
  padding: 16px;
}

.saveButton {
  width: 100%;
}

.formItem {
  @extend %input-style;
}

.formInput {
  @extend %input-style;
  margin-bottom: 16px;

  ion-label {
    margin-bottom: 8px;
  }
}

.formSelect {
  margin-bottom: 16px;

  ion-row {
    border-radius: 6px;
    border: 1px solid var(--mipro-color-dark) !important;
  }
}
