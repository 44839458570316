@import 'theme/mixins';

.modalHeaderToolbar {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--ion-safe-area-top);
  padding-block-end: 16px;
}

.modal {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --overflow: hidden !important;
  .formContent {
    --padding-top: 32px;
  }
}

.imageContainer {
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--mipro-color-background);
  height: 400px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.zoomWrapper {
  width: 100vw;
  height: 400px !important;
}

.loader {
  top: 50%;
  left: 50%;
  z-index: 1;
  position: absolute;
  padding: 18px 14px;
  border-radius: 10px;
  transform: translate3d(-50%, -50%, 0);
  background: var(--ion-background-color);
}

.pinchIcon {
  width: 14px;
  height: 14px;
  padding-inline-end: 8px;
}

.pinchRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pinchRowPadding {
  padding-block-end: 32px;
}

.captionInput {
  padding-block: 16px;
}

.fileRow {
  align-items: center;
  flex-direction: column;
}

.fileIconRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadBtn {
  max-width: 100%
}

.downloadCont {
  display: flex;
  flex-direction: column;
  .previewText {
    padding-block-end: 16px;
    padding-inline-start: 8px;
  }
  .fileIconCont {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mipro-color-white);
    width: 70px;
    height: 70px;
    background-color: var(--ion-color-primary-shade);
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .fileCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block-end: 16px;
  }
}

.fileName {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block-end: 32px;
  text-wrap: wrap;
  text-align: center;
}
