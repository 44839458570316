@import 'theme/mixins';

.button {
  position: relative;
  @include increaseTouchTargetSize(50%, 0%, 50%);
}

.content {
  .sectionTitle {
    display: block;
    margin: 16px 0;
    color: var(--mipro-color-black);
  }

  .filterRow {
    height: 30px;
    margin-right: 10px;
    margin-bottom: 8px;
    border-radius: 24px;
    --border-radius: 24px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 16px;
    --padding-end: 16px;

    &:focus-within,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &::part(native) {
      border: 1px solid var(--mipro-color-dark-text);
    }

    svg {
      font-size: 13px;
      color: var(--mipro-color-dark-text);
    }

    span {
      font-weight: 600;
      text-transform: capitalize;
      color: var(--ion-text-color-dark);
    }

    &.optionSelected {
      --background: var(--mipro-color-pale-blue);
      --color: var(--mipro-color-dark-text);

      span {
        font-size: 12px;
      }

      &::part(native) {
        border: 1px solid var(--mipro-color-pale-blue);
      }
    }
  }
}

.hideOption {
  display: none;
}

.filterSheet {
  &::part(handle) {
    background: var(--color-help-modal-handle);
  }
}

.footer {
  border-top: 1px solid var(--ion-border-color);
  padding-top: 1rem;
  margin-top: 16px;

  .buttonBar {
    display: flex;
    gap: 1rem;
    --ion-color-primary: var(--mipro-color-light-blue);

    .fullWidth {
      width: 100%;
    }
  }
}
