.content {
  --background: var(--mipro-color-white);
}

.formWrapper,
.reviewWrapper {
  padding: 0 16px 16px;
}

.formField {
  margin-top: 16px;
}

.attachmentWrapper {
  margin-top: 16px;
}

.valueSkeleton {
  width: 50%;
  height: 26px;
  margin: 0;
}

.message {
  margin: 0;
}

.fileAttachments {
  margin-top: 16px;
}

.body {
  white-space: pre-wrap;
  color: var(--ion-text-color-light);
  font-size: 13px;
  line-height: 18px;
}
