@import 'theme/mixins';

.content {
  --padding-top: 10px;
  --padding-bottom: 20px;
  --padding-start: 10px;
  --padding-end: 10px;
  --background: var(--color-gray);

  .button {
    position: absolute;
    width: 95%;
    height: 54px;
    --border-radius: 12px;
    border-radius: 12px;

    span {
      --ion-color-primary: var(--ion-color-light);
    }
  }

  .customize {
    bottom: 16px;
    --background: #202020;
    --border-color: var(--ion-color-light);
  }

  .save {
    bottom: 86px;
  }

  &.pick12Report {
    --background: var(--ion-item-background);
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;

    .actionRow {
      margin: 0;
    }
  }

  .actionRow {
    margin: -16px -16px 16px;
  }
}

.container {
  display: flex;
  touch-action: none;
  width: 100%;
  height: 100%;
}

.dropzone {
  flex: 1;
  height: 300px;
}

.gridItem {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--ion-text-color-reversed);
  border-radius: 8px;
  position: relative;
}

.gridCont {
  padding: 10px;

  .dragIcon {
    width: 13px;
    height: 13px;
    color: var(--ion-color-light-contrast);
  }

  .dragCircle {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    background: var(--ion-text-color-reversed);
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 4px 4px #00000025;
  }
}

.warningModal {
  --height: 300px !important;
}

.placelhoderHeader {
  padding: 16px 16px 8px 16px;
}

.placeholder {
  --padding-top: 0;
  --padding-bottom: 0;
}

.loader {
  float: left;
  margin: auto;

  ion-spinner {
    width: 100px;
    height: 100px;
  }
}
