@import 'theme/mixins';

.reportCell {
  display: flex;
  align-items: center;

  &.positive {
    svg,
    span {
      color: var(--color-positive) !important;
    }
  }
  &.negative {
    svg,
    span {
      color: var(--color-negative) !important;
    }
  }

  svg {
    font-size: 11px;
    margin-inline-end: 4px;
  }
}

.text {
  @include textEllipsis(2);
  white-space: break-spaces;
  word-break: break-word;
  text-align: center;
}
