.icon {
  font-size: 12px !important;
}
.active {
  color: var(--mipro-color-online-green);
}
.away {
  color: var(--mipro-color-light-yellow);
}
.inActive {
  color: var(--mipro-color-dark-gray);
}
.deactivated {
  color: var(--mipro-color-dark-gray);
}
