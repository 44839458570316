@import 'theme/mixins';

.content {
  --padding-top: 0px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
}

.modal {
  --padding-top: 16px !important;
  --padding-bottom: 16px !important;
  --background: var(--ion-background-color) !important;
  margin-right: -16px;
  margin-left: -16px;
}

.activityComponent {
  margin-right: -16px;
  margin-left: -16px;
  .activityHeader {
    padding-top: 16px;
    background-color: var(--mipro-color-white);
    .titleHeader {
      padding-top: 5px;
      .title {
        @include textEllipsis(2);
        text-align: left;
      }
    }
  }
}
.spacing {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 5px;
  background-color: white;
}

.modalWrapper {
  --height: 100% !important;
  --width: 100% !important;
  --border-radius: none;
}

.modalHeader {
  padding: 0 !important;
}

.headerWrapper {
  margin-top: 16px;

  &:empty {
    margin: 0;
  }
  &.descriptionWrapper {
    margin-top: 0px;
    text-align: left;
    text-transform: none;
    white-space: break-spaces;
  }
}

.actionsWrapper {
  margin-top: 10px;
  padding-bottom: 10px;

  .snoozeContainer {
    margin-top: 16px;
  }

  .actions {
    justify-content: space-between;

    .actionButton {
      min-width: 40px;
    }

    .actionDisabled {
      svg {
        color: var(--mipro-color-disabled-shade) !important;
      }
    }

    .inActiveSnooze {
      svg {
        color: var(--color-snoozed);
      }
    }

    .inActiveFollowUp {
      svg {
        color: var(--color-followUp);
      }
    }

    .inActiveDelete {
      svg {
        color: var(--color-delete);
      }
    }

    .activeSnooze {
      svg {
        color: var(--color-snoozed-active);
      }
    }

    .activeFollowUp {
      svg {
        color: var(--color-followUp-active);
      }
    }

    .activeDelete {
      svg {
        color: var(--color-delete-active);
      }
    }
  }
}

.snapshotLink {
  margin-bottom: 8px;
  .customerName {
    font-family: var(--ion-font-family-alt);
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 22px;
    color: var(--mipro-color-primary);
  }
  .customerNoClass {
    display: flex;
    font-family: var(--ion-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: var(--mipro-color-dark-text) !important;
  }
}

.disabledCustomerName {
  color: var(--mipro-color-black) !important;
}

.detail {
  min-height: 34px;
  padding: 8px 0;
  border-top: 1px solid var(--ion-border-color);
  align-items: center;

  &:first-child {
    border: none;
  }

  .label {
    width: 150px;
    display: block;
    line-height: 18px;
    text-transform: uppercase;
  }

  .value {
    line-height: 18px;
    white-space: pre-wrap;
    text-align: left;
    color: var(--ion-text-color-light);
  }

  .linkValue {
    position: relative;
    @include increaseTouchTargetSize(50%, 0%, 50%);
  }
}

.searchButton {
  display: flex;
  width: 220px;
}

.footerButtons {
  background: var(--ion-item-background);
  border-top: 1px solid var(--ion-border-color);
  padding: 16px;

  &:empty {
    display: none;
  }
}

.button {
  width: 100%;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.loader {
  margin-top: 16px;
  padding-bottom: 16px;
}

.card {
  margin-bottom: 16px;
}

.activityDataWrapper {
  margin-top: 16px;
}

.productListWrapper {
  margin-top: 16px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailTitle {
  flex: 1 0;
}

.detailCard {
  background-color: white;
  margin-top: 16px;

  .cardContent {
    padding-left: 16px;
    padding-right: 16px;

    .header {
      padding-top: 10px;
      padding-bottom: 5px;
    }
    .title {
      font-family: var(--ion-font-family-alt);
      --text-default-color: var(--color-gray);
      font-size: 22px;
      font-weight: 700;
      font-stretch: condensed;
    }
  }
}

.badge {
  padding-inline-start: 0;
  background: transparent;
  text-transform: uppercase;

  .priority {
    color: var(--mipro-color-light-red);
  }

  .linePrefixText {
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
  }
  .prefixIcon {
    font-size: 14px;
    width: 12px;
    height: 14px;
  }
}

.commentForm {
  background: var(--ion-item-background);
}

.commentRow {
  border-top: 1px solid var(--ion-border-color);
  padding: 16px 16px 0px;
  gap: 10px;
  align-items: flex-end;

  .commentInput {
    flex: 1;
  }

  ion-button {
    width: 50px;
  }
}

.tagSomeoneRow {
  padding: 16px;
}

.commentsButtonsWrapper {
  display: flex;
  gap: 16px;

  ion-button {
    margin: 0;
  }
}
/* delete activity start*/
.deleteBtn {
  .icon,
  .text {
    color: var(--mipro-color-light-red) !important;
  }
  .icon {
    min-width: 17px !important;
  }
}
.deleteOption {
  --background: var(--mipro-color-light-red) !important;
}

/*delete activity end*/

/*unread activity starts*/
.unReadBtn {
  .icon {
    color: var(--mipro-color-light-blue) !important;
    min-width: 17px !important;
  }
  .text {
    color: var(--mipro-color-dark-text) !important;
  }
}
/*unread activity ends*/
