.detailCard {
  background-color: white;
  padding: 16px 0px 0px;

  .title {
    --text-default-color: var(--color-gray);
    font-family: var(--ion-font-family-alt);
    font-size: 22px;
    font-weight: 700;
    font-stretch: condensed;
    margin-bottom: 24px;
    display: block;
  }

  .taskTitle {
    display: block;
    margin-bottom: 8px;
  }

  .taskBody {
    display: block;
    margin-bottom: 8px;
  }

  .timeline {
    margin: 24px auto 16px;
    padding: 0px 16px;
  }

  .rating {
    margin: 32px auto 16px;
  }
}

.toast {
  &::part(container) {
    align-items: center !important;
  }
}

.overdueDate {
  color: var(--mipro-color-light-red);
}

.textBlock {
  display: block;
  margin-bottom: 8px;
}

.lineBreak {
  white-space: pre-wrap;
  word-break: break-word;
}

.button {
  width: 100%;
}

.textItalic {
  font-style: italic;
  font-weight: normal;
  color: var(--mipro-color-dark-gray);
}

.estCloseDate {
  padding-block-end: 16px;
}

.proTipLink {
  padding-block: 0px;
  padding: 16px 0px 0px;
  margin-inline: -16px;

  ion-row {
    margin-block-end: 0px;
  }
}
