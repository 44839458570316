.employeeSpecification {
  display: flex;
  flex-direction: column;
  align-items: center;
  .employeePosition {
    display: flex;
    gap: 4px;
    align-items: center;
    text-align: center;
    .dot {
      font-weight: 900;
    }
  }
  .loggedInStatus {
    display: flex;
    gap: 4px;
    align-items: center;
    span {
      color: var(--mipro-color-dark-gray);
    }
  }
}

.deactivated {
  padding-top: 16px;
  align-self: flex-start;
}
