@import 'theme/mixins';

.visitForm {
  margin-top: 16px;
}

.formItem {
  --min-height: 26px;
  --padding-top: 0;
  --padding-bottom: 16px;
  --padding-start: 0;
  --padding-end: 0;
  --border-color: transparent;
  --background: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 1;
  --background-focused: transparent;
  --background-focused-opacity: 1;
  --background-hover: transparent;
  --background-hover-opacity: 1;

  .addButton {
    padding-inline-start: 4px;

    .plusCircle {
      font-size: 14px;
    }
  }

  &.smallItem {
    --padding-bottom: 8px;
  }

  &:last-child {
    --padding-bottom: 0;
  }

  ion-label {
    margin: 0;
    z-index: 2;
  }

  .checkIcon {
    margin-inline-end: 5px;
    color: rgba(var(--ion-color-medium-rgb), 0.5);

    &.noteIcon {
      font-size: 15px;
      color: var(--ion-color-primary);
    }

    &.active {
      color: var(--ion-color-medium);

      &.sameUser {
        color: var(--ion-color-success);
      }
    }
  }

  .checkbox {
    margin-right: 4px;
  }

  ion-textarea {
    color: var(--mipro-color-dark) !important;
  }
}

.labelRow {
  .dropdownText {
    .separator {
      margin-inline-end: 4px;
    }
  }
}

.contactButton {
  position: relative;
  @include increaseTouchTargetSize(50%, 0, 50%);

  span {
    text-decoration: underline;
  }

  &:first-child {
    margin: 0;
  }
}

.button {
  width: 100%;
}

.label {
  width: 100%;
}

.noteText {
  white-space: pre-wrap;
  color: var(--ion-color-primary);
}

.simplifiedNoteText {
  @include textEllipsis(1);
  display: -webkit-box !important;
  white-space: pre-wrap;
}

.gallerySmall {
  margin-bottom: 8px;

  ion-row {
    margin: 0 -2px;
  }
}

.visitDescription {
  margin-bottom: 8px;
  --background: transparent;
  --padding-start: 0;
  --min-height: unset;
}

.notesForm {
  .visitDescription {
    margin-bottom: 16px;
  }

  .subjectInput {
    margin-bottom: 16px;
  }

  .attachmentWrapper {
    margin-top: 16px;

    ion-row {
      margin-top: 8px;
    }
  }
}

.visitDateWrapper {
  padding-top: 16px;

  .text {
    font-size: 12px;
    color: var(--mipro-color-medium-gray);
  }
}

.visitDate {
  margin-left: 4px;
}

.visitorLabel {
  margin-right: 4px;
}

.visitDateSelect {
  padding-bottom: 16px;
  display: flex;

  .text {
    color: var(--mipro-color-dark-text);
  }

  .editButton {
    width: max-content;
    position: relative;
    padding-left: 6px;
    @include increaseTouchTargetSize(50%, 0, 0);

    span {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      font-family: var(--ion-font-family);
      text-transform: capitalize;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.cardDescription {
  @include textEllipsis(1);
  display: -webkit-box !important;
}
.opportunityMessage {
  padding-bottom:  8px;
}
.opportunityLine {
  padding-bottom: 16px;
  ion-button {
    svg {
      padding-left: 8px;
      font-size: 14px;
    }
  }
}

.dateWrapper {
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 6px;
  border: 1px solid var(--mipro-color-dark);
  background: var(--mipro-color-white);

}
.dateInput {
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  ion-label {
    margin-bottom: -8px;
  }
  padding-bottom: 8px;
}
.error {
  margin: 8px 0px;
}
.errorBorder {
  border-color: var(--mipro-color-danger) !important;
}

.attachmentsOnly {
  font-weight: normal;
}

.fileAttachments {
  margin-top: 16px;
}