@import 'theme/mixins';

.highlighted {
  --background: var(--mipro-color-pale-blue);
}

.notificationCard {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-top: 16px;
  --inner-padding-bottom: 16px;
  --inner-padding-start: 16px;
  --inner-padding-end: 16px;
  display: flex;
  flex-direction: column;
  .row {
    padding-left: 8px;
    width: 100%;
    .titleWrapper {
      display: flex;
      width: 100%;
      .aging {
        margin-inline-start: auto;
        min-width: 75px;
        padding-left: 8px;
        text-align: right;
      }
    }
  }
  .circleWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: baseline;
    .dot {
      height: 10px;
      width: 10px;
      min-width: 10px;
      border-radius: 50%;
      display: inline-block;
      background-color: transparent;
    }
    .unread {
      background-color: var(--mipro-color-unread);
    }
  }
}

.cardTitle,
.defaultCardTitle {
  font-size: 14px;
  line-height: 14px;
}

.cardTitle {
  color: var(--mipro-color-dark-text);
  strong {
    text-transform: capitalize;
  }
}

.description {
  @include textEllipsis(2);
  font-size: 12px;
  line-height: 16.8px;
  word-break: break-word;
  color: var(--mipro-color-dark-text);
  strong{
    span {
      text-transform: capitalize;
    }
  }
}
.comment {
  display: flex;
  align-items: center;
  border-left: 2px solid var(--mipro-color-light-gray);
  padding-left: 8px;
  margin-top: 8px;

  :global(.mention) {
    font-weight: 600;
    color: var(--mipro-color-dark-text) !important;
  }

  p {
    margin: 0;
  }
}
