@import 'theme/mixins';

.flex {
    display: flex;
    gap: 8px;
}
.flexColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .helpText {
        color:var(--mipro-color-dark-text);
    }
}
.warningMessage {
    margin: 16px;
}

.headerClass {
  .filter {
    .filterButton {
      &.filterNone {
        background-color: var(--ion-color-dark);
        border-color: var(--mipro-color-white);
        border: 1px solid var(--mipro-color-white);
        svg,
        span {
          color: var(--mipro-color-white) !important;
        }
      }
    }
  }
}

.filter {
  .filterButton {
    border-radius: 25px;
    background-color: var(--ion-color-primary);
    height: 30px;

    &::part(native) {
      --padding-top: 0px;
    }

    &.hasFilters {
      padding: 0px 10px;
      background: var(--mipro-color-pale-blue);
      border: none;
      svg {
        color: var(--mipro-color-dark-text) !important;
      }
      &:hover svg {
        color: var(--mipro-color-dark-text);
      }

      span {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background: var(--mipro-color-primary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.filterNone {
      min-width: 81px;
      background-color: var(--ion-item-background);
      border: 1px solid var(--ion-color-light-contrast);

      span,
      svg,
      img {
        color: var(--mipro-color-dark-text) !important;
      }
    }

    span,
    svg,
    img {
      font-weight: normal;
      font-size: 12px;
      color: var(--ion-color-primary-contrast) !important;
    }

    svg {
      font-size: 14px;
    }

    span {
      font-weight: 600 !important
    }
  }
}

.proTipWrapper {
    --background: var(--mipro-color-white);
    .refresher {
        margin: -16px -16px 16px;
    }
    .table {
        padding-inline-start: 0px;
        .td,
        .th {
                border-right: 1px solid var(--mipro-color-light-gray);
                &:not(:nth-child(1)) > div {
                min-width: 125px !important;
            }
        }
        .th {
            color: var(--mipro-color-dark-text);
        }
        .th:first-child > div,
        .td:first-child > div {
            justify-content: left;
        }
        .employeeName {
            span {
                font-size: 13px;
                font-weight: 400;
                @include textEllipsis(2);
                white-space: break-spaces;
                word-break: break-word;
                text-align: center;
            }
        }
        .rowLink {
            width: 90px;
            ion-button {
              width: 90px;
              text-align: left;
              span {
                @include textEllipsis(3);
                font-size: 15px;
                white-space: break-spaces;
                word-break: break-word;
                width: 100%;
                text-align: left;
              }
            }
          }
    }
}

