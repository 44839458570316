.reviewOrderPage {
  .headerRow {
    padding-bottom: 8px;
  }
  .createdBy {
    text-transform: capitalize;
    color: var(--ion-text-color-light);
    line-height: 14px;
  }

  .content {
    --padding-top: 16px;

    .ocnGrid {
      padding: 16px;
    }

    .ocnLineHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ocnLines {
        text-transform: uppercase;
      }

      .ocnEditBtn {
        padding-bottom: 3px;
      }
    }

    .badgeWrapper {
      margin-inline-start: 8px;
    }

    .badge {
      margin: 0;
      padding: 4px 8px;
      text-transform: none;
      height: auto;

      span {
        line-height: normal;
      }
    }

    .ocnLineGrid {
      --ion-grid-columns: 100;
      padding: 0 16px 16px 16px;

      .nameCol {
        width: 46%;
        padding: 8px 0 8px 8px;

        &.lineItemName {
          line-height: 14.4px;
        }
      }

      .dataCol {
        width: 15%;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: right;

        &.lastCol {
          width: 24%;
          padding-inline-end: 8px;
          font-weight: 700;
          line-height: 14.4px;
        }

        &.quantity {
          font-weight: 400;
          line-height: normal;
        }

        &.price {
          font-weight: 400;
          line-height: 14.4px;
        }
      }

      .evenRow {
        background: var(--ion-color-primary-contrast);
      }

      .oddRow {
        background: var(--mipro-color-background);
      }
    }

    .totalSectionGrid {
      padding: 16px;
      border-top: 1px solid var(--mipro-color-light-gray);

      .subTotalRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0;

        &.otherRow {
          padding: 0 0 8px 0;
        }
      }

      .frightTotal {
        line-height: 120%;
      }
      .separatorRow {
        padding-block: 8px;
        border-top: 1px solid var(--mipro-color-light-gray);
      }

      .total {
        line-height: 24px;
        font-weight: 600;
      }

      .totalAmt {
        font-size: 30px;
      }

      .grossProfit {
        font-style: italic;
        line-height: 120%;
      }

      .grossProfitAmt {
        line-height: 120%;
      }
    }

    .btnRow {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;

      .editBtn {
        --ion-color-primary: var(--mipro-color-primary);
        --border-color: var(--mipro-color-primary);
        width: 50%;
        padding-inline-end: 10px;
      }

      .submitBtn {
        --background: var(--mipro-color-primary);
        width: 50%;
      }
    }
  }

  ion-grid {
    --ion-grid-columns: 12;
  }

  .rowHeading {
    padding-left: 16px;

    .inputWrapper {
      max-width: 100%;
    }
  }

  .formWrapper {
    margin-bottom: 16px;
  }

  .importantNotices {
    margin: 8px 16px 16px;
  }

  .warningMessage {
    margin: 16px;
    --wrapper-margin: 0px;
    padding-block-end: 16px;

    .searchCustBtn {
      width: 100%;
      margin-block-start: 16px;
      --background: var(--mipro-color-primary);
    }
  }

  .loader {
    padding: 16px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .reviewWrapper {
    background: var(--ion-color-primary-contrast);
  }
}

.govAccModal {
  .modalButton {
    margin-top: 16px;
    width: 100%;
  }

  .modalContent {
    margin-top: 16px;
    padding-inline: 16px;
  }

  .modalText {
    font-weight: 500;
    white-space: pre-wrap;
  }

  .modalEmail {
    color: var(--ion-color-primary);
  }

  .modalTextButton {
    vertical-align: bottom;
  }
}
